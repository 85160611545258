import styled from 'styled-components';

export const Wrap = styled.div`
	width: 100%;
	display: flex;
	height: 49px;
	justify-content: center;
	align-items: center;
	justify-content: space-between;
	padding: 0.9em 1em;
	border-radius: 5px;
	border: 1px solid ${props => props.theme.separatorColor};
	.react-switch-bg {
		path {
			fill: ${props => props.theme.fontColor};
		}
		${props => {
			if (props.theme.name === 'light') {
				return `background-color: ${props.theme.separatorColor} !important`;
			} else {
				return `
            background-color: transparent !important;
            border: 1px solid ${props.theme.primaryColor} !important;
            `;
			}
		}}
	}
	.react-switch-handle {
		${props => {
			if (props.theme.name === 'light') {
				return `background-color: ${props.theme.background} !important;`;
			} else {
				return `background-color: ${props.theme.primaryColor} !important;`;
			}
		}}
		outline: none!important;
	}
`;
export const Label = styled.label`
	display: flex !important;
	align-items: center;
`;

export const Text = styled.div`
	padding-left: 5px;
	margin-bottom: 0.5em;
`;
