import { Wrap, MyError } from './styles';
import { useCallback } from 'react';
import { useFormikContext, Field, FieldProps } from 'formik';
import { useIntl } from 'react-intl';
import { useDropzone } from 'react-dropzone';
interface PropsI {
	name: string;
}
const DropAudio = (props: PropsI) => {
	const { name } = props;
	const { formatMessage } = useIntl();
	const { setFieldValue } = useFormikContext();
	const onDrop = useCallback((acceptedFiles: any) => {
		setFieldValue(name, acceptedFiles[0]);
	}, []);
	const { getRootProps, getInputProps, isDragAccept } = useDropzone({ onDrop });
	return (
		<Field name={name}>
			{(formik: FieldProps) => {
				return (
					<>
						<Wrap
							{...getRootProps()}
							className={isDragAccept ? 'drag' : ''}>
							<input
								{...getInputProps()}
								id="file"
								name="file"
							/>
							{formik.field.value === '' && <p>{formatMessage({ id: 'commons.dragAndDrop' })}</p>}
							{formik.field.value.name !== '' && <p>{formik.field.value.name}</p>}
							{formik.meta.touched && formik.meta.error && <MyError>{formik.meta.error}</MyError>}
						</Wrap>
					</>
				);
			}}
		</Field>
	);
};
export default DropAudio;
