import MenuOptions from 'common/menuOptions';
import { Item, MenuItemWrapIcon } from 'common/menuOptions/styles';
import { useIntl } from 'react-intl';
import { useOptions } from 'hooks/useOptions';
interface IOptions {
	edit: string;
}
const Options = (props: IOptions) => {
	const { formatMessage } = useIntl();
	const { edit } = props;
	const { goTo } = useOptions();
	return (
		<MenuOptions>
			<Item>
				<MenuItemWrapIcon onClick={() => goTo(edit)}>
					<i className="material-icons-outlined">edit</i>
					{formatMessage({ id: 'opt.edit' })}
				</MenuItemWrapIcon>
			</Item>
		</MenuOptions>
	);
};
export default Options;
