import { MainTitle } from 'common/text';
import { Content } from 'common/containers';
import LogsTable from 'components/tables/logs';
const Users = () => {
	return (
		<>
			<MainTitle>
				<h1>Logs</h1>
			</MainTitle>
			<Content $borderRadius=" 0 .75rem  .75rem .75rem">
				<LogsTable />
			</Content>
		</>
	);
};
export default Users;
