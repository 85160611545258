import { Wrap, DataWrap, LoginWrap, SliderWrap } from './styles';
const Layout = (props: any) => {
	const { children } = props;
	return (
		<Wrap>
			<SliderWrap>
				<img
					src={`/svg/login.svg`}
					alt="login"
				/>
			</SliderWrap>
			<DataWrap>
				<LoginWrap>
					<img
						src={`/svg/logo.svg`}
						alt={process.env.REACT_APP_NAME}
					/>
					{children}
				</LoginWrap>
			</DataWrap>
		</Wrap>
	);
};

export default Layout;
