import format from 'format-number';

export const formatNumber = (number: number) => {
	const valueFormated = format({
		decimalsSeparator: ',',
		round: 0,
	})(number, {});
	return valueFormated;
};

export const convertRoleType = (label: string) => {
	switch (label) {
		case 'Company supervisor':
			return 'company_supervisor';
		case 'Supervisor':
			return 'company_supervisor';
		case 'Customer':
			return 'customer';
		case 'Cliente':
			return 'customer';
		case 'root':
			return 'root';
		default:
			return 'customer';
	}
};
export const getNonEmptyFields = (obj: any) => {
	const fieldsToCheck = ['ddi', 'exchangeCode', 'operatorCode', 'serviceCode'];

	const result = fieldsToCheck
		.filter(field => obj[field] && obj[field].trim() !== '')
		.map(field => ({
			name: field,
			value: obj[field],
		}));

	return result;
};
