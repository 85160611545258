import { useFormik, FormikProvider } from 'formik';
import { Grid, Flex } from 'common/containers';
import * as Yup from 'yup';
import 'utils/yupExtensions';
import { useIntl } from 'react-intl';
import useManageForms from 'hooks/useManageForms';
// inputs
import Input from 'components/forms/inputs/input';
import Submit from 'components/forms/inputs/submit';
const AddUserForm = () => {
	const { formatMessage } = useIntl();
	const { submit } = useManageForms();
	const formik = useFormik({
		initialValues: {
			username: '',
			name: '',
			email: '',
			password: '',
			confirmPassword: '',
		},
		validationSchema: Yup.object({
			name: Yup.string()
				.required('Este campo es obligatorio')
				.min(6, 'Este campo debe tener mímino 6 caracteres'),
			email: Yup.string().email('Debe ser un correo válido').required('Este campo es obligatorio'),
			password: Yup.string()
				.required('Este campo es obligatorio')
				.min(6, 'Este campo debe tener mímino 6 caracteres'),
			confirmPassword: Yup.string()
				.required('Este campo es obligatorio')
				.oneOf([Yup.ref('password')], 'Los campos "Password" deben ser iguales'),
		}),
		onSubmit: async (values, { setSubmitting }) => {
			setSubmitting(true);
			await submit(values, '/users', 'Usuario creado correctamente', '/users');
			setSubmitting(false);
		},
	});
	return (
		<FormikProvider value={formik}>
			<form onSubmit={formik.handleSubmit}>
				{/* basic fields */}
				<Grid $columns={1}>
					<Input
						nameData="username"
						placeholderData={formatMessage({ id: 'users.form.userName' })}
						typeData="text"
						label={formatMessage({ id: 'users.form.userName' })}
					/>
				</Grid>
				<Grid
					$columns={2}
					$separator="2rem">
					<Input
						nameData="name"
						placeholderData={formatMessage({ id: 'users.form.name' })}
						typeData="text"
						label={formatMessage({ id: 'users.form.name' })}
					/>
					<Input
						nameData="email"
						placeholderData={formatMessage({ id: 'users.form.email' })}
						typeData="text"
						label={formatMessage({ id: 'users.form.email' })}
					/>
				</Grid>
				<Grid
					$columns={2}
					$separator="2rem">
					<Input
						nameData="password"
						placeholderData={formatMessage({ id: 'users.form.password' })}
						typeData="password"
						label={formatMessage({ id: 'users.form.password' })}
					/>
					<Input
						nameData="confirmPassword"
						placeholderData={formatMessage({ id: 'users.form.confirmPassword' })}
						typeData="password"
						label={formatMessage({ id: 'users.form.confirmPassword' })}
					/>
				</Grid>

				<Flex $margin="2rem 0 0 0">
					<Submit
						onClick={formik.handleSubmit}
						disabled={formik.isSubmitting}>
						{formatMessage({ id: 'commons.save' })}
					</Submit>
				</Flex>
			</form>
		</FormikProvider>
	);
};
export default AddUserForm;
