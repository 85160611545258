import { Wrap, MenuItemWrap } from './styles';
import userService from 'services/userService';
import Menu from 'common/menu';
import { MenuItem } from '@szhsin/react-menu';
import { useIntl } from 'react-intl';
import { useToogleLanguage } from 'hooks/useToogleLanguage';
import Avatar from './avatar';

const AvatarMenu = () => {
	const { formatMessage } = useIntl();
	const { languageChanger, state } = useToogleLanguage();
	return (
		<Wrap>
			<Menu button={Avatar}>
				<MenuItem>
					{state !== 'es' && (
						<MenuItemWrap onClick={() => languageChanger('es')}>Español</MenuItemWrap>
					)}
					{state !== 'en' && (
						<MenuItemWrap onClick={() => languageChanger('en')}>English</MenuItemWrap>
					)}
				</MenuItem>
				<MenuItem>
					<MenuItemWrap onClick={() => userService.logOut()}>
						{formatMessage({ id: 'logout.title' })}
					</MenuItemWrap>
				</MenuItem>
			</Menu>
		</Wrap>
	);
};
export default AvatarMenu;
