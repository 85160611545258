import { Content, Tab, Tabs } from 'common/containers';
import { useState } from 'react';
import { Subtitle } from 'common/text';
import DeletePortabilityForm from 'components/forms/portability/delete';
import { Nav } from '../styles';
import { useIntl } from 'react-intl';
import Batch from 'components/forms/portability/batch';

const DeletePortability = () => {
	const [type, setType] = useState('single');
	const { formatMessage } = useIntl();
	return (
		<div>
			<Nav>
				<Tabs>
					<Tab
						className={type === 'single' ? 'active' : ''}
						onClick={() => setType('single')}>
						Manual
					</Tab>
					<Tab
						className={type === 'batch' ? 'active' : ''}
						onClick={() => setType('batch')}>
						{formatMessage({ id: 'blacklists.file' })}
					</Tab>
				</Tabs>
			</Nav>
			<Content $borderRadius="0 .75rem .75rem .75rem">
				{type === 'single' && (
					<div>
						<Subtitle $margin="0 0 1rem 0">Elimina un número de portabilidad</Subtitle>
						<DeletePortabilityForm />
					</div>
				)}
				{type === 'batch' && (
					<div>
						<Subtitle $margin="0 0 1rem 0">
							Sube un archivo CSV con los datos de los numeros
						</Subtitle>
						<Batch actionType="delete" />
					</div>
				)}
			</Content>
		</div>
	);
};
export default DeletePortability;
