import { Wrap } from './styles';
import routes from 'utils/navigationRoutes';
import { useIntl } from 'react-intl';
import { NavLink } from 'react-router-dom';
const SideNav = () => {
	const { formatMessage } = useIntl();
	const userRole = 'root';
	return (
		<Wrap>
			{routes.map(route => {
				if (route && route.roles.includes(userRole)) {
					return (
						<li key={route.id}>
							<NavLink to={route.url}>
								<i className="material-icons-outlined">{route.icon}</i>
								{formatMessage({ id: route.title })}
							</NavLink>
						</li>
					);
				}
				return null;
			})}
		</Wrap>
	);
};
export default SideNav;
