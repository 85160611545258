import { useContext } from 'react';
import { useIntl } from 'react-intl';
import DataTable from 'common/dataTable';
import { GlobalsContext } from 'context/globals';
import { useDataTable } from 'hooks/useDataTableDigitalk';
import { getNonEmptyFields } from 'utils/formatData';
const DigitalkTable = () => {
	const { state } = useContext(GlobalsContext);
	const { formatMessage } = useIntl();

	const { isLoading, response } = useDataTable(
		'portability',
		getNonEmptyFields(state),
		state.reload
	);
	const columns = [
		{
			name: formatMessage({ id: 'table.ddi' }),
			selector: (row: any) => row.Ddi,
			sortable: true,
			wrap: true,
		},
		{
			name: formatMessage({ id: 'table.operatorCode' }),
			selector: (row: any) => row.OperatorCode,
			sortable: true,
			wrap: true,
		},
		{
			name: formatMessage({ id: 'table.serviceCode' }),
			selector: (row: any) => row.ServiceCode,
			sortable: true,
			wrap: true,
		},
		{
			name: formatMessage({ id: 'table.exchangeCode' }),
			selector: (row: any) => row.ExchangeCode,
			sortable: true,
			wrap: true,
		},
	];
	return (
		<div>
			<DataTable
				data={response}
				columns={columns}
				progressPending={isLoading}
				title="Digitakl"
				paginationServer
			/>
		</div>
	);
};

export default DigitalkTable;
