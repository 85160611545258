import styled from 'styled-components';

export const Wrap = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
`;
export const MyButton = styled.button`
	width: 130px;
	line-height: 1.5;
	font-weight: 700;
	letter-spacing: 0.05rem;
	font-size: 12px;
	padding: 0.5rem 0;
	background-color: ${props => props.theme.primaryColor};
	color: #fff;
	border-radius: 5px;
	transition: all 0.2s ease-in;
	border: none;
	cursor: pointer;
	&:hover {
		color: #fff;
		span {
			padding-right: 25px;
			&:after {
				opacity: 1;
				right: 0;
			}
		}
	}
	&:disabled {
		opacity: 0.5;
		&:hover {
			transform: scale(1);
		}
	}
	span {
		cursor: pointer;
		display: inline-block;
		position: relative;
		transition: 0.5s;
		&:after {
			font-family: 'Material Icons';
			content: 'login';
			font-size: 1rem;
			-webkit-font-feature-settings: 'liga' 1;
			-moz-font-feature-settings: 'liga' 1;
			font-feature-settings: 'liga' 1;
			position: absolute;
			opacity: 0;
			top: 0;
			line-height: 1rem;
			right: -20px;
			transition: 0.5s;
			color: #fff;
		}
	}
`;
