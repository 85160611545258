import styled from 'styled-components';
export const Wrap = styled.div`
	width: 100%;
	.react-switch-bg {
		path {
			fill: ${props => props.theme.fontColor};
		}
		${props => {
			if (props.theme.name === 'light') {
				return `background-color: ${props.theme.separatorColor} !important`;
			} else {
				return `
            background-color: transparent !important;
            border: 1px solid ${props.theme.primaryColor} !important;
            `;
			}
		}}
	}
	.react-switch-handle {
		${props => {
			if (props.theme.name === 'light') {
				return `background-color: ${props.theme.background} !important;`;
			} else {
				return `background-color: ${props.theme.primaryColor} !important;`;
			}
		}}
		outline: none!important;
	}
`;
