import { useIntl } from 'react-intl';
import { MainTitle } from 'common/text';
import BackButton from 'common/buttons/back';
import { Content } from 'common/containers';
import EditUserForm from 'components/forms/users/edit';
import useFetchData from 'hooks/useFetchData';
import Skeleton from 'common/skeleton';
import { useParams } from 'react-router-dom';
const UserAdd = () => {
	const { formatMessage } = useIntl();
	const { id } = useParams<{ id: string }>();
	const { response, isLoading } = useFetchData(`users/${id}`);
	return (
		<>
			<MainTitle>
				<h1>{formatMessage({ id: 'users.edit' })}</h1>
				<BackButton />
			</MainTitle>
			<Content>
				{isLoading && <Skeleton count={10} />}
				{!isLoading && (
					<EditUserForm
						data={response}
						id={id}
					/>
				)}
			</Content>
		</>
	);
};
export default UserAdd;
