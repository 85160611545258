import * as CryptoJS from 'crypto-js';

const secretKey = process.env.REACT_APP_ENCRYPT_KEY || 'default_secret_key';

const encrypt = (data: any): string => {
	const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), secretKey);
	return ciphertext.toString();
};

const decrypt = (data: string): any => {
	const bytes = CryptoJS.AES.decrypt(data, secretKey);
	const plaintext = bytes.toString(CryptoJS.enc.Utf8);
	try {
		return JSON.parse(plaintext);
	} catch {
		return plaintext;
	}
};

const cryptoService = {
	encrypt,
	decrypt,
};

export default cryptoService;
