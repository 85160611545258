import { useContext } from 'react';
import { MainTitle, Subtitle } from 'common/text';
import { Content, Grid } from 'common/containers';
import DigitalkTable from 'components/tables/digitalk';
import DigitalkSearch from 'components/forms/digitalkSearch';
import { GlobalsContext } from 'context/globals';
import AddPortability from 'components/portability/add';
import DeletePortability from 'components/portability/delete';
const Dashboard = () => {
	const { state } = useContext(GlobalsContext);
	return (
		<>
			<MainTitle>
				<h1>Portabilidad - Digitalk</h1>
			</MainTitle>
			<Content>
				<Subtitle $margin="0 0 1rem 0">Búsqueda de registros en Digitalk:</Subtitle>
				<DigitalkSearch />
				{(state.ddi || state.exchangeCode || state.operatorCode || state.serviceCode) && (
					<DigitalkTable />
				)}
			</Content>
			<Grid
				$columns={2}
				$separator="2rem">
				<AddPortability />
				<DeletePortability />
			</Grid>
		</>
	);
};
export default Dashboard;
