import { useState, useEffect } from 'react';
import api from '../services/api';
import { toast } from 'react-toastify';
interface ExtraParams {
	name: any;
	value: any;
}
export const useDataTable = (
	url: string,
	extraParams: ExtraParams[] = [{ name: null, value: null }],
	refresh: number = 1
) => {
	const [isLoading, setIsLoading] = useState(true);
	const [response, setResponse] = useState<any>([]);
	const [perPage, setPerPage] = useState<any>(20);
	const [page, setPage] = useState(1);
	const createUrl = (page: number) => {
		const urlWithParams = new URL(`${process.env.REACT_APP_API}/${url}`);
		urlWithParams.searchParams.set('pageNumber', `${page}`);
		urlWithParams.searchParams.set('pageSize', perPage);
		if (extraParams && extraParams.length > 0) {
			extraParams.forEach((param: ExtraParams) => {
				if (param.value) urlWithParams.searchParams.set(param.name, param.value);
			});
		}
		return urlWithParams;
	};
	const fetchData = async (page: number) => {
		try {
			setIsLoading(true);
			let urlParams = createUrl(page).toString();
			let response = await api.get(urlParams);

			setResponse(response.data.docs);

			setIsLoading(false);
		} catch (err) {
			toast.error('Error al cargar los datos');
		}
	};
	const handlePageChange = (page: number) => {
		setPage(page);
		fetchData(page);
	};
	const handlePerRowsChange = async (newPerPage: number, page: number) => {
		try {
			setIsLoading(true);
			let urlParams = createUrl(page).toString();
			let response = await api.get(urlParams);
			setResponse(response.data.docs);
			setPerPage(newPerPage);
			setIsLoading(false);
		} catch (err) {
			toast.error('Error al cargar los datos');
		}
	};
	useEffect(() => {
		fetchData(page);
	}, [url, perPage, extraParams[0].value, refresh]);
	return {
		isLoading,
		response,
		setResponse,
		perPage,
		page,
		handlePageChange,
		handlePerRowsChange,
		fetchData,
	};
};
