import { toast } from 'react-toastify';
import api from 'services/api';
import userService from 'services/userService';
import { useNavigate } from 'react-router-dom';
import cryptoService from 'services/crypto.service';
interface loginData {
	username: string;
	password: string;
}
export const useLogin = () => {
	let navigate = useNavigate();
	const performLogin = async (data: loginData) => {
		try {
			const token = await api.post('/auth', {
				username: data.username,
				password: cryptoService.encrypt(data.password),
			});
			userService.setToken(token.data.token);
			const user = await api.get('/auth/current-user');
			if (user) {
				userService.setUser(user.data);
				navigate('/');
			} else {
				userService.setToken(null);
			}
		} catch (err: any) {
			userService.setToken(null);
			let message = 'Error inesperado';
			if (err.response && err.response.data && err.response.data.message) {
				message = err.response.data.message;
			}
			toast.error(message);
		}
	};
	return { performLogin };
};
