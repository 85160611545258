export const SUPPORTED_FORMATS = [
	'audio/ogg',
	'audio/mpeg',
	'audio/mp3',
	'audio/wav',
	'',
	'video/mp4',
];
export const SUPPORTED_FORMATS_PLAIN = ['text/csv'];

export const acceptedFileTypes = {
	'text/csv': ['.csv'],
	'application/vnd.ms-excel': ['.xls', '.xlsx'],
};
