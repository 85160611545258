import styled from 'styled-components';

interface IContent {
	$borderRadius?: string;
	$separator?: string;
	$margin?: string;
}
export const Content = styled.section<IContent>`
	width: 100%;
	background: ${props => props.theme.background};
	border-radius: ${({ $borderRadius }) => ($borderRadius ? $borderRadius : '.75rem')};
	padding: 1.75rem;
	margin-top: ${({ $separator }) => ($separator ? $separator : '0')};
	margin-bottom: ${({ $margin }) => ($margin ? $margin : '0')};
	${props => {
		if (props.theme.name === 'light') {
			return 'box-shadow: 0 1px 15px rgba(0,0,0,.04),0 1px 6px rgba(0,0,0,.04);';
		} else {
			return 'box-shadow: 0 1px 15px rgba(0,0,0,.1),0 1px 8px rgba(0,0,0,.1);';
		}
	}}
`;

interface IGrid {
	$columns?: number;
	$margin?: string;
	disabled?: boolean;
	$separator?: string;
}
export const Grid = styled.div<IGrid>`
	width: 100%;
	display: grid;
	grid-template-columns: repeat(${({ $columns }) => ($columns ? $columns : '3')}, 1fr);
	grid-column-gap: 2.5%;
	grid-row-gap: 30px;
	margin-bottom: ${({ $margin }) => ($margin ? $margin : '0')};
	margin-top: ${({ $separator }) => ($separator ? $separator : '0')};
	${({ disabled }) =>
		disabled &&
		`
    position: relative;
    opacity: 0.5;
    pointer-events: none;
  `}
	${({ $columns }) => {
		if ($columns === 2) {
			return `
        @media screen and (max-width: 768px) {
          grid-template-columns: repeat(1,1fr);
        }
      `;
		}
		if ($columns === 3) {
			return `
        @media screen and (max-width: 768px) {
          grid-template-columns: repeat(2,1fr);
        }
        @media screen and (max-width: 480px) {
          grid-template-columns: repeat(1,1fr);
        }
      `;
		}
		if ($columns === 4) {
			return `
        @media screen and (max-width: 1024px) {
          grid-template-columns: repeat(3,1fr);
        }
        @media screen and (max-width: 768px) {
          grid-template-columns: repeat(2,1fr);
        }
        @media screen and (max-width: 480px) {
          grid-template-columns: repeat(1,1fr);
        }
      `;
		}
		if ($columns === 5) {
			return `
        @media screen and (max-width: 1060px) {
          grid-template-columns: repeat(4,1fr);
        }
        @media screen and (max-width: 890px) {
          grid-template-columns: repeat(2,1fr);
        }
        @media screen and (max-width: 680px) {
          grid-template-columns: repeat(1,1fr);
        }
      `;
		}
	}}
`;
interface IFlex {
	$margin?: string;
	$h?: string;
	$v?: string;
	$gap?: string;
}
export const Flex = styled.div<IFlex>`
	display: flex;
	margin: ${props => (props.$margin ? props.$margin : '0')};
	justify-content: ${props => (props.$h ? props.$h : 'space-between')};
	align-items: ${props => (props.$v ? props.$v : 'strech')};
	gap: ${props => (props.$gap ? props.$gap : '0')};
	flex-wrap: wrap;
`;

interface ICustomTableCell {
	color?: string;
}
export const CustomTableCell = styled.div<ICustomTableCell>`
	width: 100%;
	text-align: left;

	p {
		color: ${props => (props.color ? props.color : props.theme.text)} !important;
		padding-left: 8px;
	}
`;
export const Tabs = styled.div`
	display: flex;
`;
export const Tab = styled.div`
	font-size: 1.2em;
	padding: 0.5em 1em 0.5em 1em;
	border-radius: 5px 5px 0 0;
	background-color: ${props => props.theme.menuLines};
	cursor: pointer;
	&.active {
		background: ${props => props.theme.background};
	}
`;

export const LoadingWrap = styled.div`
	width: 100%;
	height: 300px;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const MyFormInline = styled.form`
	padding: 1rem 0;
	width: 100%;
	display: grid;
	grid-template-columns: calc(100% - 50px) 50px;
	input {
		margin-bottom: 0 !important ;
	}
`;
export const DivMyFormInline = styled.div`
	padding: 0 0 1rem 0;
	width: 100%;
	display: grid;
	grid-template-columns: calc(100% - 50px) 50px;
	input {
		margin-bottom: 0 !important ;
	}
`;
export const MyFormCircleSubmit = styled.button<any>`
	width: 30px;
	height: 30px;
	background: ${props => props.theme.primaryColor};
	border-radius: 50%;
	align-self: center;
	justify-self: center;
	border: none;
	color: white;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	cursor: pointer;
	&.disabled {
		opacity: 0.5;
		cursor: not-allowed;
	}
`;
