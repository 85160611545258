import api from 'services/api';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import formData from 'utils/formData';
const useManageForms = () => {
	const navigate = useNavigate();
	const submit = async (values: any, url: string, message: string, redirect: string) => {
		try {
			await api.post(url, values);
			toast.success(message);
			navigate(redirect);
		} catch (err) {
			toast.error('Error');
		}
	};
	const deleteCall = async (url: string, message: string, redirect: string) => {
		try {
			await api.delete(url);
			toast.success(message);
			navigate(redirect);
		} catch (err) {
			toast.error('Error');
		}
	};

	const submitFormData = async (values: any, url: string, message: string, redirect: string) => {
		const config = {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		};
		const data = formData(values);
		try {
			await api.post(url, data, config);
			toast.success(message);
			navigate(redirect);
		} catch (err) {
			toast.error('Error');
		}
	};
	const submitFormDataNoRedirect = async (values: any, url: string, message: string) => {
		const config = {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		};
		const data = formData(values);
		try {
			let response = await api.post(url, data, config);
			toast.success(message);
			return response.data;
		} catch (err) {
			toast.error('Error');
			return null;
		}
	};

	const postCall = async (values: any, url: string) => {
		try {
			let response = await api.post(url, values);
			return response.data;
		} catch (err) {
			toast.error('Lo sentimos ha ocurrido un error, intenta de nuevo.');
			return null;
		}
	};
	const editFormData = async (values: any, url: string, message: string, redirect: string) => {
		const config = {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		};
		const data = formData(values);
		try {
			await api.patch(url, data, config);
			toast.success(message);
			navigate(redirect);
		} catch (err) {
			toast.error('Error');
		}
	};
	const edit = async (values: any, url: string, message: string, redirect: string) => {
		try {
			await api.put(url, values);
			toast.success(message);
			navigate(redirect);
		} catch (err) {
			toast.error('Error');
		}
	};

	return {
		submit,
		edit,
		submitFormData,
		editFormData,
		submitFormDataNoRedirect,
		postCall,
		deleteCall,
	};
};
export default useManageForms;
