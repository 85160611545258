import { useContext } from 'react';
import { GlobalsContext } from 'context/globals';
import { useNavigate } from 'react-router-dom';
import api from 'services/api';
import { toast } from 'react-toastify';
export const useOptions = () => {
	const {
		state: { socket },
	} = useContext(GlobalsContext);
	const navigate = useNavigate();

	const downloadReport = (name: string, id: string, ignoreCache: boolean = false) => {
		socket.emit('getDetailed', {
			campaignIdsIn: [id],
			fileName: name,
			ignoreCache: ignoreCache,
		});
	};
	const deleteCall = async (url: string) => {
		try {
			await api.delete(url);
			navigate(window.location.pathname, { replace: true });
			return true;
		} catch (err) {
			toast.error('Error al eliminar, intenta de nuevo');
			return false;
		}
	};
	const downloadAduio = (url: string = '') => {
		if (typeof window !== 'undefined' && window !== null) {
			window.open(`${process.env.REACT_APP_API}${url}`, '_blank');
		}
	};

	const goTo = (url: string) => {
		navigate(url);
	};
	return { downloadReport, goTo, downloadAduio, deleteCall };
};
