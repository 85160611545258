import { useContext } from 'react';
import { useFormik, FormikProvider } from 'formik';
import { Grid, Flex } from 'common/containers';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';
import { GlobalsContext } from 'context/globals';
// inputs
import Input from 'components/forms/inputs/input';
import Submit from 'components/forms/inputs/submit';
// utils
import useManageForms from 'hooks/useManageForms';
const DeletePortabilityForm = () => {
	const { formatMessage } = useIntl();
	const { dispatch } = useContext(GlobalsContext);
	const { deleteCall } = useManageForms();
	const formik = useFormik({
		initialValues: {
			ddi: '',
		},
		validationSchema: Yup.object({
			ddi: Yup.string().required('Este campo es obligatorio'),
		}),
		onSubmit: async (values, { setSubmitting }) => {
			setSubmitting(true);
			await deleteCall(`/portability/${values.ddi}`, 'Número eliminado correctamente', '/');
			dispatch({ ...values, reload: Math.random() });
			formik.resetForm();
			setSubmitting(false);
		},
	});
	return (
		<FormikProvider value={formik}>
			<form onSubmit={formik.handleSubmit}>
				<Grid $columns={1}>
					<Input
						nameData="ddi"
						placeholderData={formatMessage({ id: 'table.ddi' })}
						typeData="text"
						label={formatMessage({ id: 'table.ddi' })}
					/>
					<div></div>
					<div></div>
					<div></div>
				</Grid>
				<Flex $margin="2rem 0 0 0">
					<Submit
						onClick={formik.handleSubmit}
						disabled={formik.isSubmitting}>
						{formatMessage({ id: 'commons.delete' })}
					</Submit>
				</Flex>
			</form>
		</FormikProvider>
	);
};
export default DeletePortabilityForm;
