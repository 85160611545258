import { useDataTable } from 'hooks/useDataTable';
import { Grid } from 'common/containers';
import DataTable from 'common/dataTable';
import { useIntl } from 'react-intl';
import Search from 'common/tableFilters/search';
import ToggleStatus from 'common/toggleStatus';
import moment from 'moment';
import Options from './options';

const UsersTable = () => {
	const { formatMessage } = useIntl();
	const {
		response,
		isLoading,
		totalDocs,
		perPage,
		handlePageChange,
		handlePerRowsChange,
		search,
		setSearch,
		showHidden,
		setShowHidden,
	} = useDataTable('users', [{ name: null, value: null }]);
	const columns = [
		{
			name: formatMessage({ id: 'table.user' }),
			selector: (row: any) => row.username,
			sortable: true,
			wrap: true,
		},
		{
			name: formatMessage({ id: 'table.mail' }),
			selector: (row: any) => row.email,
			sortable: true,
			wrap: true,
		},
		{
			name: formatMessage({ id: 'table.createdAt' }),
			selector: (row: any) => row.createdAt,
			cell: (row: any) => moment(row.createdAt).format('lll'),
			sortable: true,
			wrap: true,
		},
		{
			name: formatMessage({ id: 'table.updatedAt' }),
			selector: (row: any) => row.updatedAt,
			cell: (row: any) => moment(row.updatedAt).format('lll'),
			sortable: true,
			wrap: true,
		},
		{
			name: formatMessage({ id: 'table.user.status' }),
			selector: (row: any) => row.status,
			cell: (row: any) => {
				return (
					<ToggleStatus
						id={row._id}
						value={row.status}
						url="/users/"
					/>
				);
			},
			wrap: true,
		},
		{
			name: formatMessage({ id: 'table.options' }),
			cell: (row: any) => {
				return <Options edit={`/users/edit/${row._id}`} />;
			},
		},
	];
	return (
		<>
			<Grid
				$columns={2}
				$separator="1rem">
				<Search
					search={search}
					setSearch={setSearch}
				/>
			</Grid>
			<DataTable
				data={response}
				columns={columns}
				progressPending={isLoading}
				pagination
				paginationServer
				paginationTotalRows={totalDocs}
				onChangeRowsPerPage={handlePerRowsChange}
				onChangePage={handlePageChange}
				paginationPerPage={perPage}
				toggleHidden={true}
				showHidden={showHidden}
				setShowHidden={setShowHidden}
			/>
		</>
	);
};
export default UsersTable;
