import { MainTitle } from 'common/text';
import { Content } from 'common/containers';
import PortabilityTable from 'components/tables/portability';
import { useIntl } from 'react-intl';
const Potability = () => {
	const { formatMessage } = useIntl();
	return (
		<>
			<MainTitle>
				<h1>{formatMessage({ id: 'portability.title' })}</h1>
			</MainTitle>
			<Content $borderRadius=" 0 .75rem  .75rem .75rem">
				<PortabilityTable />
			</Content>
		</>
	);
};
export default Potability;
