import styled from 'styled-components';
export const Wrap = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 3em 20px;
	border-width: 2px;
	border-radius: 2px;
	border-color: ${props => props.theme.separatorColor};
	border-style: dashed;
	color: ${props => props.theme.fontColor};
	cursor: pointer;
	outline: none;
	position: relative;
	transition: border 0.24s ease-in-out;
	position: relative;
	&.drag {
		border-color: ${props => props.theme.primaryColor};
	}
`;
export const MyError = styled.div`
	width: 100%;
	color: red;
	padding: 0.3em;
	position: absolute;
	top: 103%;
	font-size: 12px;
	z-index: 9996;
	text-align: left;
`;
