import { useIntl } from 'react-intl';
import { MainTitle } from 'common/text';
import { Content } from 'common/containers';
import AddButton from 'common/buttons/add';
import UsersTable from 'components/tables/users';
const Users = () => {
	const { formatMessage } = useIntl();
	return (
		<>
			<MainTitle>
				<h1>{formatMessage({ id: 'users.title' })}</h1>
				<AddButton url={`/users/add`} />
			</MainTitle>
			<Content $borderRadius=" 0 .75rem  .75rem .75rem">
				<UsersTable />
			</Content>
		</>
	);
};
export default Users;
