import { useIntl } from 'react-intl';
import { MainTitle } from 'common/text';
import BackButton from 'common/buttons/back';
import { Content } from 'common/containers';
import AddUserForm from 'components/forms/users/add';
const UserAdd = () => {
	const { formatMessage } = useIntl();
	return (
		<>
			<MainTitle>
				<h1>{formatMessage({ id: 'users.add' })}</h1>
				<BackButton />
			</MainTitle>
			<Content>
				<AddUserForm />
			</Content>
		</>
	);
};
export default UserAdd;
