import { useContext } from 'react';
import { useFormik, FormikProvider } from 'formik';
import { Grid, Flex } from 'common/containers';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';
import { GlobalsContext } from 'context/globals';
// inputs
import Input from 'components/forms/inputs/input';
import Submit from 'components/forms/inputs/submit';
// utils
const AddServerForm = () => {
	const { formatMessage } = useIntl();
	const { dispatch } = useContext(GlobalsContext);
	const formik = useFormik({
		initialValues: {
			ddi: '',
			exchangeCode: '',
			operatorCode: '',
			serviceCode: '',
		},
		validationSchema: Yup.object({
			ddi: Yup.string().test('atLeastOne', '*Al menos debes llenar un campo', (value, context) => {
				const { parent } = context;
				return !!(value || parent.exchangeCode || parent.operatorCode || parent.serviceCode);
			}),
			exchangeCode: Yup.string().test(
				'atLeastOne',
				'*Al menos debes llenar un campo',
				(value, context) => {
					const { parent } = context;
					return !!(value || parent.ddi || parent.operatorCode || parent.serviceCode);
				}
			),
			operatorCode: Yup.string().test(
				'atLeastOne',
				'*Al menos debes llenar un campo',
				(value, context) => {
					const { parent } = context;
					return !!(value || parent.ddi || parent.exchangeCode || parent.serviceCode);
				}
			),
			serviceCode: Yup.string().test(
				'atLeastOne',
				'*Al menos debes llenar un campo',
				(value, context) => {
					const { parent } = context;
					return !!(value || parent.ddi || parent.exchangeCode || parent.operatorCode);
				}
			),
		}),
		onSubmit: async (values, { setSubmitting }) => {
			setSubmitting(true);
			dispatch({ ...values, reload: Math.random() });
			setSubmitting(false);
		},
	});
	return (
		<FormikProvider value={formik}>
			<form onSubmit={formik.handleSubmit}>
				<Grid $columns={2}>
					<Input
						nameData="ddi"
						placeholderData={formatMessage({ id: 'table.ddi' })}
						typeData="text"
						label={formatMessage({ id: 'table.ddi' })}
					/>
					<Input
						nameData="operatorCode"
						placeholderData={formatMessage({ id: 'table.operatorCode' })}
						typeData="text"
						label={formatMessage({ id: 'table.operatorCode' })}
					/>
					<Input
						nameData="exchangeCode"
						placeholderData={formatMessage({ id: 'table.exchangeCode' })}
						typeData="text"
						label={formatMessage({ id: 'table.exchangeCode' })}
					/>
					<Input
						nameData="serviceCode"
						placeholderData={formatMessage({ id: 'table.serviceCode' })}
						typeData="text"
						label={formatMessage({ id: 'table.serviceCode' })}
					/>
				</Grid>
				<Flex $margin="2rem 0 0 0">
					<Submit
						onClick={formik.handleSubmit}
						disabled={formik.isSubmitting}>
						{formatMessage({ id: 'commons.search' })}
					</Submit>
				</Flex>
			</form>
		</FormikProvider>
	);
};
export default AddServerForm;
