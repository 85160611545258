const messages_es = {
	'login.welcome': 'Bienvenido, inicia sesión',
	'logout.title': 'Cerrar sesión',

	'commons.back': 'Regresar',
	'commons.save': 'Guardar',
	'commons.validate': 'Validar',
	'commons.update': 'Actualizar',
	'commons.rowsPerPage': 'Filas por página',
	'commons.of': 'de',
	'commons.noData': 'No se encontraron registros',
	'commons.step': 'Paso',
	'commons.filter': 'Filtrar...',
	'commons.dragAndDrop': 'Da click o arrastra el archivo aquí',
	'commons.findNumber': 'Buscar número',
	'commons.initDate': 'Fecha inicial',
	'commons.endDate': 'Fecha final',
	'commons.downloadAll': 'Descargar todos',
	'commons.consolidated': 'Consolidado',
	'commons.status': 'Estatus',
	'commons.apply': 'Aplicar',
	'commons.credits': 'Créditos',
	'commons.next': 'Siguiente',
	'commons.download': 'Descargar',
	'commons.have': 'Tienes',
	'commons.error': 'error(es)',
	'commons.required': 'Campo requerido',
	'commons.textQuantity': 'Cantidad de caracteres:',
	'commons.createAudio': 'Crear audio',
	'commons.launch': 'Lanzar',
	'commons.start': 'Iniciar',
	'commons.daily': 'Diario',
	'commons.weekly': 'Semanal',
	'commons.biweekly': 'Quincenal',
	'commons.monthly': 'Mensual',
	'commons.company_supervisor': 'Supervisor',
	'commons.customer': 'Cliente',
	'commons.downloading': 'Descargando',
	'commons.root': 'root',
	'commons.add': 'Añadir',
	'commons.insert': 'Inserción',
	'commons.delete': 'Eliminación',
	'commons.today': 'Hoy',
	'commons.historic': 'Histórico',
	'commons.search': 'Buscar',

	'statusCampaign.active': 'Activa',
	'statusCampaign.paused': 'Pausada',
	'statusCampaign.onHold': 'En espera',
	'statusCampaign.toClone': 'Por clonar',
	'statusCampaign.cloning': 'Clonando',

	'blaster.type.normal': 'Normal',
	'blaster.type.press': 'Press',

	'month.enero': 'Enero',
	'month.febrero': 'Febrero',
	'month.marzo': 'Marzo',
	'month.abril': 'Abril',
	'month.mayo': 'Mayo',
	'month.junio': 'Junio',
	'month.julio': 'Julio',
	'month.agosto': 'Agosto',
	'month.septiembre': 'Septiembre',
	'month.octubre': 'Octubre',
	'month.noviembre': 'Noviembre',
	'month.diciembre': 'Diciembre',

	'table.name': 'Nombre',
	'table.nameCampaign': 'Nombre de campaña',
	'table.nameBlacklist': 'Nombre de la lista',
	'table.user': 'Usuario',
	'table.mail': 'Correo',
	'table.company': 'Compañía',
	'table.role': 'Rol',
	'table.credits': 'Créditos',
	'table.status': 'Estado',
	'table.user.status': 'Estado de usuario',
	'table.company.status': 'Estado de compañía',
	'table.options': 'Opciones',
	'table.createDate': 'Fecha de creación',
	'table.createdAt': 'Creado el',
	'table.updatedAt': 'Actualizado el',
	'table.maxTps': 'Máximo de TPS',
	'table.previewer': 'Audio cargado',
	'table.records': 'Cantidad de registros',
	'table.number': 'Número',
	'table.operation': 'Operación',
	'table.server': 'Servidor',
	'table.description': 'Descripción',
	'table.serial': 'Número de serie',
	'table.dataCenter': 'Centro',
	'table.list': 'Lista',
	'table.init': 'Inicio',
	'table.initCampaign': 'Inicio de campaña',
	'table.end': 'Final',
	'table.endCampaign': 'Final de campaña',
	'table.success': 'Llamadas exitosas',
	'table.error': 'No contestadas',
	'table.onHold': 'En espera',
	'table.invalid': 'Números inválidos',
	'table.press': 'Press',
	'table.registers': 'Registros cargados',
	'table.blacklist': 'Lista negra',
	'table.priority': 'Prioridad',
	'table.calling': 'Llamando',
	'table.showInactive': 'Mostrar inactivos',
	'table.totalRegisters': 'Registros cargados',
	'table.campaignType': 'Tipo de campaña',
	'table.blasterType': 'Tipo de blaster',
	'table.audio': 'Nombre de audio',
	'table.updateDate': 'Fecha de actualización',
	'table.assignedNumbers': 'Números asignados',
	'table.initTime': 'Hora de inicio',
	'table.endTime': 'Hora de finalización',
	'table.deliveryTime': 'Franja horaria',
	'table.timeZoneTitle': 'Tipo de horario',
	'table.contactation': 'Contactación',
	'table.userToReport': 'Usuario a reportar',
	'table.period': 'Periodicidad',
	'table.reportType': 'Tipo de reporte',
	'table.mainMail': 'Correo principal',
	'table.mailList': 'Lista de correos',
	'table.module': 'Módulo',
	'table.details': 'Detalles',
	'table.ddi': 'DDI',
	'table.operatorCode': 'Operator code',
	'table.exchangeCode': 'Exchange code',
	'table.serviceCode': 'Service code',
	'table.action': 'Acción',

	'opt.delete': 'Eliminar',
	'opt.edit': 'Editar',
	'opt.credits': 'Cargar créditos',
	'opt.downloadReport': 'Descargar reporte',
	'opt.downloadAudio': 'Descargar audio',
	'opt.downloadFile': 'Descargar archivo',
	'opt.clone': 'Clonar campaña',

	'dashboard.title': 'Dashboard',
	'dashboard.currentCampaign': 'Campañas en curso',
	'dashboard.onholdCampaign': 'Campañas en espera',
	'dashboard.pausedCampaign': 'Campañas pausadas',
	'dashboard.callsmadeCampaign': 'Llamadas realizadas',
	'dashboard.successcallsCampaign': 'Llamadas exitosas',
	'dashboard.contactpercentageCampaign': 'Porcentaje de contactación',
	'dashboard.campaignsStatus': 'Estado de las campañas',

	'users.title': 'Usuarios',
	'users.welcome': 'Estos son sus usuarios registrados:',
	'users.add': 'Añadir usuario',
	'users.edit': 'Editar usuario',
	'users.credits': 'Añadir creditos',
	'users.form.name': 'Nombre',
	'users.form.userName': 'Nombre de usuario',
	'users.form.email': 'Correo eléctronico',
	'users.form.userType': 'Tipo de usuario',
	'users.form.password': 'Contraseña',
	'users.form.updatePassword': '¿Desea actualizar la contraseña?',
	'users.form.confirmPassword': 'Confirma contraseña',
	'users.form.company': 'Selecciona una compañía',
	'users.form.role': 'Selecciona un rol',
	'users.form.prefix': 'Prefijo cliente perimeta',
	'users.form.prefixWord': 'Prefijo',
	'users.form.credits': 'Créditos',
	'users.form.currentCredits': 'Créditos actuales',
	'users.form.aniDefault': 'Número ANI por defecto',
	'users.form.prefixPbx': 'VDI/DID de transferencia',
	'users.form.ivr': 'Usuario cuenta con IVR',
	'users.form.ivrKey': 'Clave IVR',
	'users.form.countryCode': 'Selecciona un código de país',
	'users.form.limitCredits': 'Límite de créditos',
	'users.form.hasVariableTTS': 'Usuario cuenta con TTS variable',
	'users.form.hasNeuronalTTS': 'Usuario cuenta con TTS neuronal',
	'users.form.deliveryTime': 'Franja horaria',

	'company.title': 'Compañías',
	'company.welcome': 'Estas son sus compañías registradas:',
	'company.add': 'Añadir compañía',
	'company.edit': 'Editar compañía',
	'company.form.name': 'Nombre de la compañía',
	'company.form.maxTps': 'Máximo de TPS',
	'company.form.callerId': 'Identificador de llamada en cabecera',
	'company.form.internationalCalls': 'Llamadas internacionales',

	'campaigns.title': 'Campañas',
	'campaigns.welcome': 'Estas son sus campañas activas:',
	'campaigns.add': 'Añadir campaña',
	'campaigns.edit': 'Editar campaña',
	'campaigns.noCredits':
		'Lo sentimos, no cuentas con los créditos suficientes para crear una camapaña, comunícate con tu agente de ventas para obetener más créditos',
	'campaigns.maxErrors':
		'Lo sentimos, debes tener al menos 1 número válido para configurar la campaña, intenta nuevamente con otro archivo',
	'camp.nav.identification': 'Identificación',
	'camp.nav.contacts': 'Contactos',
	'camp.nav.message': 'Mensaje',
	'camp.nav.configuration': 'Configuración',
	'camp.nav.launch': 'Lanzamiento',
	'camp.welcome.title': '¡Vamos a comenzar!',
	'camp.welcome.description':
		'Dale un nombre a tu campaña, te servirá par a identificarla más tarde',
	'camp.contact.toltip1':
		'Permite cargar archivos en formato CSV/ Excel, máximo 1’048,575 registros y 6 columnas por archivo.',
	'camp.contactp2.description': 'En lista lista puedes ver los primeros 5 registros de tu archivo',
	'camp.contactp2.tooltip1':
		'Permite seleccionar la columna de numero telefónico al que se desea marcar.',
	'camp.contactp2.tooltip2': 'Columna de datos que se mostrara al descargar el reporte final.',
	'camp.contactp2.tooltip3': 'Excluye los registros duplicados, para evitar doble marcación.',
	'camp.contactp2.tooltip4':
		'Desactiva función de exclusión de fila de encabezados. Default: Activo',
	'camp.contactp2.tooltip5':
		'Validación de reglas de archivo de carga: Validación de Números telefónicos de acuerdo al código de país. Validación de Caracteres especiales',
	'camp.contactp2.tooltip6':
		'Resultado y descarga de reporte de la validación de reglas de archivo de carga.',
	'campaigns.clone.title': 'Clonar campaña',

	'audiosList.title': 'Lista de audios',
	'audiosList.description':
		'Selecciona un audio que tengas previamente guardado en tu biblioteca de audios.',
	'upload.title': 'Subir audio',
	'txt2speech.title': 'Texto a audio',
	'txt2speech.voiceId': 'Selecciona tipo de voz',
	'txt2speech.instructions': 'Escribe el texto que se convertirá en voz:',

	'txt2speechVar.title': 'Texto a audio variable',
	'txt2speechVar.voiceId': 'Selecciona tipo de voz',
	'txt2speechVar.instructions':
		'Escribe el texto que se convertirá en voz, puedes introducir variables en tu texto, el audio muestra lo escucharás con el primer resultado de tu archivo de contactos:',
	'txtToSpeechVar.normal': 'Texto',
	'txtToSpeechVar.tel': 'Teléfono',
	'txtToSpeechVar.date': 'Fecha',

	'camp.form.name': 'Nombre',
	'camp.form.columnNumber': 'Selecciona columna números',
	'camp.form.campAux': 'Selecciona campo auxiliar',
	'camp.form.eliminateRepeat': 'Eliminar repetidos',
	'camp.form.hasHeaders': 'Archivo tiene cabeceras',
	'camp.form.validateFile': 'Validar archivo',
	'camp.form.attempts': 'Intentos',
	'camp.form.attempts.tooltip': 'Número de intentos a contactar a registros no exitosos.',
	'camp.form.audioReproductions': 'Número de reproducciones de audio:',
	'camp.form.audioReproductions.tooltip':
		'Número de repeticiones de audio en una llamada contestada. Opciones: 1,2,3 reproducciones',
	'camp.form.riningTime': 'Tiempo de timbrado',
	'camp.form.riningTime.tooltip': 'Ajuste del tiempo de timbrado.',
	'camp.form.riningTimeDuration': 'Máximo 1 minuto',
	'camp.form.callDuration': 'Duración de llamada',
	'camp.form.callDuration.tooltip':
		'Ajuste del tiempo de duración de una llamada contestada y transferida.',
	'camp.form.callMaxTime': 'Máximo 5 minutos',
	'camp.form.voiceMail': 'Detección buzón de voz',
	'camp.form.voiceMail.tooltip':
		'Función de corte de llamada al detectar un buzón de voz. Default: Desactivado',
	'camp.form.leaveVoiceMail': 'Dejar mensaje en buzón de voz',
	'camp.form.leaveVoiceMail.tooltip':
		'Función de liberación de audio completo a buzón de voz. Default: Desactivado',
	'camp.form.conactPercentage': 'Porcentaje de contactación',
	'camp.form.conactPercentage.tooltip': 'Porcentaje de registros marcados con éxito. Default: 100%',
	'camp.form.blasterType': 'Tipo de blaster',
	'camp.form.blasterType.tooltip':
		'Opciones de marcación. Plano: Envía mensaje sin interacción Press: Redirecciona llamada al DID o IP Asignada.',
	'camp.form.velocityMode': 'Modo de velocidad',
	'camp.form.velocityMode.tooltip':
		'Modo de velocidad de ejecución de la campaña. En caso de que la campaña sea de tipo normal, siempre será de forma automatica.',
	'camp.form.velocityMode.opt.automatic': 'Automatico',
	'camp.form.priority': 'Prioridad',
	'camp.form.priority.tooltip':
		'Velocidad de ejecución de la campaña. Si el modo es automatico estará fijo a velocidad máxima.',
	'camp.form.priority.opt': 'Máxima',
	'camp.form.tps.tooltip': 'Velocidad de ejecución de la campaña.',
	'camp.form.pressElection': 'Elige el número de press',
	'camp.form.pressElection.tooltip': 'Digito que transfiere llamada al Call Center.',
	'camp.form.headerCallerId': 'Identificador de llamada en cabecera',
	'camp.form.headerCallerId.tooltip':
		'Información que será mostrada al ejecutivo telefónico. Default: Activado',
	'camp.form.internationalCalls': 'Llamadas internacionales',

	'launch.campaign': 'campaña',
	'launch.totalContacts': 'Total de contactos:',
	'launch.totalErrors': 'Total de errores:',
	'launch.removeData': 'Remover duplicados y errores:',
	'launch.attempts': 'Vueltas:',
	'launch.riningTime': 'Tiempo de timbrado:',
	'launch.durationCall': 'Duración de llamada:',
	'launch.voiceMail': 'Detección buzón de voz:',
	'launch.leaveVoiceMail': 'Dejar mensaje en buzón de voz:',
	'launch.audioReproductions': 'Reproducciones de audio:',
	'launch.init': 'Inicia:',
	'launch.end': 'Termina:',
	'launch.priority': 'Prioridad:',
	'launch.basterType': 'Tipo de blaster:',
	'launch.pressElection': 'Elección de press:',

	'ivr.title': 'IVR',
	'ivr.welcome': 'Estas son sus campañas IVR activas:',
	'ivr.add': 'Añadir campaña IVR',
	'ivr.noCredits':
		'Lo sentimos, no cuentas con los créditos suficientes para crear una camapaña, comunícate con tu agente de ventas para obetener más créditos',
	'ivr.nav.identification': 'Identificación',
	'ivr.nav.contacts': 'Contactos',
	'ivr.nav.message': 'Mensaje',
	'ivr.nav.configuration': 'Configuración',
	'ivr.nav.launch': 'Lanzamiento',
	'ivr.welcome.title': '¡Vámos a comenzar!',
	'ivr.welcome.description':
		'Dale un nombre a tu campaña, te servirá par a identificarla más tarde',
	'ivr.contact.toltip1':
		'Permite cargar archivos en formato CSV/ Excel, máximo 1’048,575 registros y 6 columnas por archivo.',
	'ivr.contactp2.description': 'En lista lista puedes ver los primeros 5 registros de tu archivo',
	'ivr.contactp2.tooltip1':
		'Permite seleccionar la columna de numero telefónico al que se desea marcar.',
	'ivr.contactp2.tooltip2': 'Columna de datos que se mostrara al descargar el reporte final.',
	'ivr.contactp2.tooltip3': 'Excluye los registros duplicados, para evitar doble marcación.',
	'ivr.contactp2.tooltip4':
		'Desactiva función de exclusión de fila de encabezados. Default: Activo',
	'ivr.contactp2.tooltip5':
		'Validación de reglas de archivo de carga: Validación de Números telefónicos de acuerdo al código de país. Validación de Caracteres especiales',
	'ivr.contactp2.tooltip6':
		'Resultado y descarga de reporte de la validación de reglas de archivo de carga.',

	'ivr.form.name': 'Nombre',
	'ivr.form.columnNumber': 'Selecciona columna números',
	'ivr.form.campAux': 'Selecciona campo auxiliar',
	'ivr.form.eliminateRepeat': 'Eliminar repetidos',
	'ivr.form.hasHeaders': 'Archivo tiene cabeceras',
	'ivr.form.validateFile': 'Validar archivo',
	'ivr.form.attempts': 'Intentos',
	'ivr.form.attempts.tooltip': 'Número de intentos a contactar a registros no exitosos.',
	'ivr.form.audioReproductions': 'Número de reproducciones de audio:',
	'ivr.form.audioReproductions.tooltip':
		'Número de repeticiones de audio en una llamada contestada. Opciones: 1,2,3 reproducciones',
	'ivr.form.riningTime': 'Tiempo de timbrado',
	'ivr.form.riningTime.tooltip': 'Ajuste del tiempo de timbrado.',
	'ivr.form.riningTimeDuration': 'Máximo 1 minuto',
	'ivr.form.callDuration': 'Duración de llamada',
	'ivr.form.callDuration.tooltip':
		'Ajuste del tiempo de duración de una llamada contestada y transferida.',
	'ivr.form.callMaxTime': 'Máximo 5 minutos',
	'ivr.form.voiceMail': 'Detección buzón de voz',
	'ivr.form.voiceMail.tooltip':
		'Función de corte de llamada al detectar un buzón de voz. Default: Desactivado',
	'ivr.form.leaveVoiceMail': 'Dejar mensaje en buzón de voz',
	'ivr.form.leaveVoiceMail.tooltip':
		'Función de liberación de audio completo a buzón de voz. Default: Desactivado',
	'ivr.form.blasterType': 'Tipo de blaster',
	'ivr.form.blasterType.tooltip':
		'Opciones de marcación. Plano: Envía mensaje sin interacción Press: Redirecciona llamada al DID o IP Asignada.',
	'ivr.form.velocityMode': 'Modo de velocidad',
	'ivr.form.velocityMode.tooltip':
		'Modo de velocidad de ejecución de la campaña. En caso de que la campaña sea de tipo normal, siempre será de forma automatica.',
	'ivr.form.velocityMode.opt.automatic': 'Automatico',
	'ivr.form.priority': 'Prioridad',
	'ivr.form.priority.tooltip':
		'Velocidad de ejecución de la campaña. Si el modo es automatico estará fijo a velocidad máxima.',
	'ivr.form.priority.opt': 'Máxima',
	'ivr.form.tps.tooltip': 'Velocidad de ejecución de la campaña.',
	'ivr.form.pressElection': 'Elige el número de press',
	'ivr.form.pressElection.tooltip': 'Digito que transfiere llamada al Call Center.',
	'ivr.form.headerCallerId': 'Identificador de llamada en cabecera',
	'ivr.form.headerCallerId.tooltip':
		'Información que será mostrada al ejecutivo telefónico. Default: Activado',
	'ivr.form.internationalCalls': 'Llamadas internacionales',

	'audios.title': 'Audios',
	'audios.welcome': 'Estas son sus audios activos:',
	'audios.add': 'Añadir audio',
	'audios.intro':
		'Administración de audios, alta, baja y borrado. Formatos permitidos: MP3, MP4, WAV, GSM, OGG',
	'audios.form.audioName': 'Nombre del audio',

	'api.title': 'API',
	'api.welcome': 'Estas son sus campañas API activas:',

	'blacklists.title': 'Listas negras',
	'blacklists.welcome': 'Estas son sus listas negras:',
	'blacklists.add': 'Añadir blacklist',
	'blacklists.edit': 'Editar blacklist',
	'blacklists.file': 'Archivo',
	'blacklists.phoneNumber': 'Número telefónico',
	'blacklists.form.name': 'Nombre de la lista',
	'blacklists.form.user': 'Selecciona un usuario',
	'blacklists.edit.addNumber': 'Añadir número',
	'blacklists.edit.addToolTip':
		'Alta unitaria o en ráfaga (Archivo) de números telefónicos, máximo 1 millón.',
	'blacklists.edit.descriptionAdd': 'Agrega hasta 10 números de forma manual',
	'blacklists.edit.descriptionAddFile':
		"Agrega cualquier cantidad de números por medio de un archivo csv/xlsx, debe contener una cabecera llamada 'numero'",
	'blacklists.edit.descriptionDeleteFile':
		"Elimina cualquier cantidad de números por medio de un archivo csv/xlsx, debe contener una cabecera llamada 'numero'",
	'blacklists.edit.deleteNumber': 'Eliminar número',
	'blacklists.edit.deleteDescription': 'Elimina hasta 10 números de forma manual',
	'blacklists.edit.deleteToolTip':
		'Baja unitaria o en ráfaga (Archivo) de números telefónicos, máximo 1 millón.',
	'blacklists.table.title': 'Números en blacklist',
	'blacklists.table.tooltip': 'Consulta y borrado de números de forma unitaria.',
	'blacklists.log.tooltip': 'Muestra el historial de la actividad en esta funcionalidad.',

	'servers.title': 'Servidores',
	'servers.welcome': 'Estos son tus servidores:',
	'servers.add': 'Añadir servidor',
	'servers.edit': 'Editar servidor',
	'servers.form.serverName': 'Nombre del servidor',
	'servers.form.serverIP': 'IP del servidor',
	'servers.form.serialNumber': 'Número de serie',
	'servers.form.dataCenter': 'Centro',
	'servers.form.description': 'Descripción',

	'ani.title': 'Listas ANI',
	'ani.title2': 'Listas de indentificadores de llamada',
	'ani.welcome': 'Estas son tus listas de identificador de llamada:',
	'ani.add': 'Añadir lista ANI',
	'ani.edit': 'Editar lista ANI',
	'ani.form.listName': 'Nombre de la lista',
	'ani.form.user': 'Selecciona un usuario',
	'ani.form.listType': 'Tipo de lista',

	'reports.title': 'Reportes',
	'reports.welcome': 'Estos son tus reportes:',
	'reports.auto.open': 'Automatizar reporte',
	'reports.auto.tooltip':
		'Habilitar los reportes automatizados para facilitar la medición del desempeño de cada campaña.',
	'reports.auto.title': 'Configuración de reportes automaticos',
	'reports.auto.description':
		'Si no colocas una lista de correos sólo se va a mandar al correo registrado en tu usuario',
	'reports.auto.form.period': 'Periodicidad',
	'reports.auto.form.email': 'Correo',
	'reports.accumulated': 'Reporte acumulado',
	'reports.accumulated.tooltip':
		'Descargar la información general de las campañas seleccionadas por periodo, con el total de registros, llamadas exitosas y porcentajes de contactación',
	'title.grapic.callsGroupedByStatus': 'llamadas agrupadas por status',
	'column-count-exceeded': 'Lo sentimos, su archivo no puede contener más de 6 columnas',
	'logs.title': 'Logs',
	'settings.title': 'Configuración',
	'chart[success]': 'Llamadas exitosas',
	'chart[error]': 'No contestada',
	'chart[onHold]': 'En espera',
	'chart[Invalid status]': 'Números inválidos',
	'chart[Press]': 'Press',
	'chart[blacklist]': 'Lista negra',
	'chart[calling]': 'Llamando',
	'chart.pie.title': 'Gráfica de llamadas realizadas por estado',
	'chart.table.title': 'Listado de contactación por estado',

	'automatedReports.title': 'Reportes automatizados',
	'automatedReports.add': 'Añadir reporte',
	'automatedReports.edit': 'Editar reporte',
	'automatedReports.form.perioricity': 'Periodicidad',
	'automatedReports.form.type': 'Tipo de reporte',
	'automatedReports.form.user': 'Selecciona un usuario',
	'automatedReports.form.mailList': 'Lista de correos',
	'automatedReports.form.username': 'Nombre de usuario',
	'automatedReports.form.password': 'Contraseña',
	'automatedReports.form.host': 'Servidor',
	'automatedReports.form.port': 'Puerto',
	'automatedReports.form.secure': 'Seguro FTP/SFTP',

	'logs.recordName': 'Nombre de campaña',
	'logs.recordId': 'Id del registro',
	'logs.oldStatusCampaign': 'Estado anterior',
	'logs.statusCampaign': 'Status Actual',
	'logs.userName': 'Usuario',
	'logs.oldValue': 'Valor anterior',
	'logs.value': 'Valor actual',

	'timeZones.title': 'Zonas horarias',
	'timeZones.add': 'Añadir zona horaria',
	'timeZones.edit': 'Editar zona horaria',
	'timeZones.success': 'Zona horaria guardada exitosamente',
	'timeZones.form.title': 'Título',
	'timeZones.form.initTime': 'Hora de inicio',
	'timeZones.form.endTime': 'Hora de finalización',

	'portability.title': 'Portabilidad',
};

export default messages_es;
