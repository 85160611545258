import { Wrap, Label } from './styles';
import { Field, FieldProps, useFormikContext } from 'formik';
import HelperTooltip from 'common/helperTooltip';
import SwitchComponent from 'react-switch';

export interface InputProps {
	nameData: string;
	label?: string;
	isDisabled?: boolean;
	textHelper?: string;
}
const Switch = (props: InputProps) => {
	const { setFieldValue } = useFormikContext();
	const { nameData, label, isDisabled, textHelper = '' } = props;
	const handleChange = (status: any) => {
		setFieldValue(nameData, status);
	};
	return (
		<>
			<Field name={nameData}>
				{({ field }: FieldProps) => (
					<div>
						<Wrap>
							{label && (
								<Label>
									{label}
									{textHelper !== '' && <HelperTooltip text={textHelper} />}
								</Label>
							)}
							<SwitchComponent
								onChange={handleChange}
								checked={field.value}
								uncheckedIcon={undefined}
								width={40}
								height={20}
								handleDiameter={13}
								activeBoxShadow={undefined}
								disabled={isDisabled}
							/>
						</Wrap>
					</div>
				)}
			</Field>
		</>
	);
};

export default Switch;
