import { Wrap, Content } from './styles';
interface HelperTooltipProps {
	text: string;
	$marginRight?: string;
}
const HelperTooltip = (props: HelperTooltipProps) => {
	const { text, $marginRight } = props;
	return (
		<Wrap $marginRight={$marginRight}>
			<img
				src="/img/helper.png"
				alt="ayuda"
			/>
			<Content>{text}</Content>
		</Wrap>
	);
};
export default HelperTooltip;
