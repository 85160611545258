import styled from 'styled-components';
export const Nav = styled.div`
	display: flex;
	justify-content: space-between;
	h2 {
		margin: 0;
		padding-left: 5px;
		font-weight: normal;
		transform: translateY(5px);
		display: flex;
	}
`;
export const Tabs = styled.div`
	display: flex;
`;
export const Tab = styled.div`
	font-size: 1.2em;
	padding: 0.5em 1em 0.5em 1em;
	border-radius: 5px 5px 0 0;
	background-color: ${props => props.theme.menuLines};
	cursor: pointer;
	&.active {
		background: ${props => props.theme.background};
	}
`;
