import { MyButton, Wrap } from './styles';
import LoadingSmall from 'common/loadingSmall';
const LoginButton = (props: any) => {
	return (
		<Wrap>
			<MyButton
				type={props.type || 'submit'}
				disabled={props.disabled}
				{...props}>
				{props.disabled ? <LoadingSmall /> : <span>{props.children}</span>}
			</MyButton>
		</Wrap>
	);
};

export default LoginButton;
