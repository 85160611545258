import api from 'services/api';
import { toast } from 'react-toastify';
import formData from 'utils/formData';
import { AxiosRequestConfig, ResponseType } from 'axios';
const useHandlePortabilityNumbers = () => {
	const addInBatchNumbers = async (values: any) => {
		toast.success('Comenzamos a agregar los números, esto puede tardar unos minutos');
		try {
			const config: AxiosRequestConfig = {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
				responseType: 'blob' as ResponseType,
			};

			const data = formData(values);
			const response = await api.post(`/portability/batch-add`, data, config);

			const blob = new Blob([response.data], { type: 'text/csv' });
			const url = URL.createObjectURL(blob);
			const link = document.createElement('a');
			link.href = url;

			const today = new Date();
			const formattedDate = `${today.getDate()}-${today.getMonth() + 1}-${today
				.getFullYear()
				.toString()
				.slice(-2)}`;

			link.download = `update-portability-numbers-${formattedDate}.csv`;
			link.click();

			URL.revokeObjectURL(url);

			toast.success(`El proceso ha terminado, revisa el archivo de respuesta`);
		} catch {
			toast.error('Error al agregar los números');
		}
	};

	const deleteInBatchNumbers = async (values: any) => {
		toast.success('Comenzamos a eliminar los números, esto puede tardar unos minutos');
		try {
			const config: AxiosRequestConfig = {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
				responseType: 'blob' as ResponseType,
			};

			const data = formData(values);
			const response = await api.post(`/portability/batch-delete`, data, config);

			const blob = new Blob([response.data], { type: 'text/csv' });
			const url = URL.createObjectURL(blob);
			const link = document.createElement('a');
			link.href = url;

			const today = new Date();
			const formattedDate = `${today.getDate()}-${today.getMonth() + 1}-${today
				.getFullYear()
				.toString()
				.slice(-2)}`;

			link.download = `delete-portability-numbers-${formattedDate}.csv`;
			link.click();

			URL.revokeObjectURL(url);

			toast.success(`El proceso ha terminado, revisa el archivo de respuesta`);
		} catch {
			toast.error('Error al eliminar los números');
		}
	};

	return {
		addInBatchNumbers,
		deleteInBatchNumbers,
	};
};
export default useHandlePortabilityNumbers;
