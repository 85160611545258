import { useContext, useState, useEffect } from 'react';
import { GlobalsContext } from 'context/globals';

export const useToogleLanguage = () => {
	const {
		state: { language },
		dispatch,
	} = useContext(GlobalsContext);
	const [state, setState] = useState<String>('');
	useEffect(() => {
		setState(localStorage.getItem('language') || 'es');
	}, []);
	const languageChanger = (selected: string) => {
		setState(selected);
		languageChangerOnStore(selected);
	};
	const languageChangerOnStore = (languageS: string) => {
		localStorage.setItem('language', languageS);
		dispatch({ language: languageS });
	};
	return { language, languageChanger, state };
};
