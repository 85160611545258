import { useDataTable } from 'hooks/useDataTable';
import { Grid } from 'common/containers';
import DataTable from 'common/dataTable';
import { useIntl } from 'react-intl';
import Search from 'common/tableFilters/search';
import moment from 'moment';

const UsersTable = () => {
	const { formatMessage } = useIntl();
	const {
		response,
		isLoading,
		totalDocs,
		perPage,
		handlePageChange,
		handlePerRowsChange,
		search,
		setSearch,
	} = useDataTable('logs', [{ name: null, value: null }]);
	const columns = [
		{
			name: formatMessage({ id: 'table.action' }),
			selector: (row: any) => row.action,
			sortable: true,
			wrap: true,
		},
		{
			name: formatMessage({ id: 'table.ddi' }),
			selector: (row: any) => row.ddi,
			sortable: true,
			wrap: true,
		},
		{
			name: formatMessage({ id: 'table.operatorCode' }),
			selector: (row: any) => row.operatorCode,
			sortable: true,
			wrap: true,
		},
		{
			name: formatMessage({ id: 'table.exchangeCode' }),
			selector: (row: any) => row.exchangeCode,
			sortable: true,
			wrap: true,
		},
		{
			name: formatMessage({ id: 'table.serviceCode' }),
			selector: (row: any) => row.serviceCode,
			sortable: true,
			wrap: true,
		},
		{
			name: formatMessage({ id: 'table.user' }),
			selector: (row: any) => row.name,
			sortable: true,
			wrap: true,
		},
		{
			name: formatMessage({ id: 'table.mail' }),
			selector: (row: any) => row.email,
			sortable: true,
			wrap: true,
		},
		{
			name: formatMessage({ id: 'table.createdAt' }),
			selector: (row: any) => row.createdAt,
			cell: (row: any) => moment(row.createdAt).format('lll'),
			sortable: true,
			wrap: true,
		},
	];
	return (
		<>
			<Grid
				$columns={2}
				$separator="1rem">
				<Search
					search={search}
					setSearch={setSearch}
				/>
			</Grid>
			<DataTable
				data={response}
				columns={columns}
				progressPending={isLoading}
				pagination
				paginationServer
				paginationTotalRows={totalDocs}
				onChangeRowsPerPage={handlePerRowsChange}
				onChangePage={handlePageChange}
				paginationPerPage={perPage}
				toggleHidden={false}
			/>
		</>
	);
};
export default UsersTable;
