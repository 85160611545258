import Switch from 'react-switch';
import { useState } from 'react';
import { Wrap } from './styles';
import api from 'services/api';
import { toast } from 'react-toastify';
interface PropsInterface {
	url: string;
	id: string;
	value: boolean;
}
const ToogleStatus = (props: PropsInterface) => {
	const { url, id, value } = props;
	const [actualValue, setActualValue] = useState(value);
	const handleChange = async (status: boolean) => {
		try {
			api.put(`${url}${id}`, { status: `${status}` });
			toast.success(`Actualizado correctamente`);
		} catch (err) {
			toast.error('Lo sentimos, tuvimos un error, intente nuevamente');
		}

		setActualValue(status);
	};
	return (
		<Wrap>
			<Switch
				onChange={handleChange}
				checked={actualValue}
				width={40}
				height={20}
				handleDiameter={13}
			/>
		</Wrap>
	);
};
export default ToogleStatus;
