import { Wrap } from './styles';
import { useFormik, FormikProvider } from 'formik';
import * as Yup from 'yup';
import Input from 'components/forms/inputs/input';
import { Flex, Grid } from 'common/containers';
import { useLogin } from 'hooks/useLogin';
import Submit from '../inputs/login';
const LoginForm = () => {
	const { performLogin } = useLogin();
	const formik = useFormik({
		initialValues: {
			username: '',
			password: '',
		},
		validationSchema: Yup.object({
			username: Yup.string().required('Este campo es obligatorio'),
			password: Yup.string().required('Este campo es obligatorio'),
		}),
		onSubmit: async (values, { setSubmitting }) => {
			setSubmitting(true);
			await performLogin(values);
			setSubmitting(false);
		},
	});
	return (
		<Wrap>
			<FormikProvider value={formik}>
				<Grid $columns={1}>
					<Input
						nameData="username"
						typeData="text"
						label="Usuario"
					/>
					<Input
						nameData="password"
						typeData="password"
						label="Contraseña"
					/>
				</Grid>
				<Flex $margin="2rem 0 0 0">
					<Submit
						onClick={formik.handleSubmit}
						disabled={formik.isSubmitting}>
						Iniciar sesión
					</Submit>
				</Flex>
			</FormikProvider>
		</Wrap>
	);
};

export default LoginForm;
