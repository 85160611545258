import styled from 'styled-components';

interface WrapProps {
	$marginRight?: string;
}
export const Wrap = styled.div<WrapProps>`
	margin-top: 2px;
	margin-left: 10px;
	cursor: pointer;
	position: relative;
	margin-right: ${props => (props.$marginRight ? props.$marginRight : '0')};
	img {
		display: block;
		width: 20px;
		height: 20px;
	}
	&:hover > div {
		display: block;
	}
`;
export const Content = styled.div`
	position: absolute;
	padding: 10px;
	border-radius: 5px;
	width: 500px;
	max-width: 200px;
	top: 0;
	transform: translate(-45%, -103%);
	background-color: ${props => props.theme.body};
	border: 1px solid ${props => props.theme.bordercolor};
	z-index: 9998 !important;
	font-size: 12px;
	display: none;
	font-weight: bolder;
	color: ${props => props.theme.contentFontColor};
`;
