import { HeaderWrap, LeftContent, CenterContent, RightContent } from './styles';
import Hamburger from './hamburger';
import FullScreenButton from './fullScreenButton';
import AvatarMenu from './avatarMenu';
// import ToogleTheme from './toogleTheme';
const Header = () => {
	return (
		<HeaderWrap>
			<LeftContent>
				<Hamburger />
			</LeftContent>
			<CenterContent>
				<img
					src={`/svg/logo.svg`}
					alt={process.env.REACT_APP_NAME}
				/>
			</CenterContent>
			<RightContent>
				{/* <ToogleTheme /> */}
				<FullScreenButton />
				<AvatarMenu />
			</RightContent>
		</HeaderWrap>
	);
};
export default Header;
